<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M230.222 123.667V221.444C230.222 231.263 222.263 239.222 212.444 239.222H43.5556C33.7372 239.222 25.7778 231.263 25.7778 221.444V123.667M123.556 48.1111C123.556 30.9289 109.627 17 92.4444 17C75.2623 17 61.3333 30.9289 61.3333 48.1111C61.3333 65.2933 75.2623 79.2222 92.4444 79.2222C101.15 79.2222 123.556 79.2222 123.556 79.2222M123.556 48.1111C123.556 56.588 123.556 79.2222 123.556 79.2222M123.556 48.1111C123.556 30.9289 137.484 17 154.667 17C171.849 17 185.778 30.9289 185.778 48.1111C185.778 65.2933 171.849 79.2222 154.667 79.2222C145.961 79.2222 123.556 79.2222 123.556 79.2222M123.556 79.2222V239.222M21.3333 123.667H234.667C242.03 123.667 248 117.697 248 110.333V92.5556C248 85.1918 242.03 79.2222 234.667 79.2222H21.3333C13.9695 79.2222 8 85.1918 8 92.5556V110.333C8 117.697 13.9695 123.667 21.3333 123.667Z"
      stroke="currentColor"
      stroke-width="15"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
